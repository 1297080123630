<template>
    <section :id="`${inline ? `empty-${illustration}` : 'empty'}`" :class="{ 'empty' : inline }">
        <Illustration :name="illustration" @loaded="loaded"/>
        <h2>
            <slot name="title"/>
        </h2>
        <p>
            <slot name="description"/>
        </p>
        <figure v-if="!!link && !inline" ref="arrow" :style="`left: ${left}px; width: ${width}px; height: ${height}px;`">
            <svg viewBox="0 0 672.5 260.3" preserveAspectRatio="none" class="curve">
                <path vector-effect="non-scaling-stroke" class="dashed"
                      d="m3.4 2.5s-19.6 189.1 124 205.2c128.1 14.3 132.2-63.6 200.3-84.1 90.1-27 128.4 122.1 342.3 134.2"/>
            </svg>
            <svg viewBox="0 0 20 28.6" class="arrow">
                <path d="M2.5,2.5l13.6,13L2.5,26.1"/>
            </svg>
        </figure>
    </section>
</template>

<script>
import Illustration from '@/components/Illustration.vue';

export default {
    name: 'Empty',
    components: {
        Illustration,
    },
    props: {
        illustration: {
            type: String,
            default: '',
        },
        inline: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            button: Object,
            arrow: Object,
            link: Object,
            left: 0,
            width: 0,
            height: 0,
        };
    },
    created() {
        window.addEventListener('resize', this.onWindowResize);
    },
    mounted() {
        this.button = this.$parent.$el.nextElementSibling;
        this.arrow = this.$refs.arrow;
        this.link = this.$el.querySelector('a');
        this.onWindowResize();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            if (this.link && !this.inline) {
                this.$nextTick(() => {
                    this.left = this.link.offsetLeft + (this.link.offsetWidth * 0.5);
                    const arrowClientRect = this.arrow.getBoundingClientRect();
                    const arrowLeftPos = (this.width === 0) ? arrowClientRect.left + this.left : arrowClientRect.left;
                    this.width = this.button.offsetLeft - arrowLeftPos - 20;
                    this.height = this.button.offsetTop - arrowClientRect.top + 30;
                });
            }
        },
        loaded() {
            this.onWindowResize();
        },
    },
};
</script>

<style lang="less">
@import '../assets/css/variables';

section:is(#empty, .empty) {
    --stroke-width: 2px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    &:is(#empty) {
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 500px;
        width: 80%;

        h2 {
            font-size: 3.8rem;
            line-height: 1.1;
        }

        @media @laptop-screen {
            --stroke-width: 5px;

            h2 {
                font-size: 6rem;
            }

            p {
                font-size: 3.6rem;
            }
        }
    }

    h2 {
        font-size: 3.2rem;
        line-height: 1;
        margin: 1.2rem 0;
        color: rgb(var(--primary-color));

        @media @full-desktop-screen {
            font-size: 3.8rem;
            line-height: 1.1;
        }
    }

    p {
        margin: 0;
        font-size: 2.4rem;
        line-height: 1.25;
        font-weight: var(--semibold-weight);

        :is(a, .link) {
            position: relative;
            text-decoration: none;
            color: inherit;
            transition: color var(--time-transition) var(--cubic-transition);

            &:hover {
                color: rgb(var(--primary-color));
            }

            &::after {
                content: "";
                background: rgb(var(--primary-color));
                height: var(--stroke-width);
                width: 100%;
                border-radius: 3px;
                position: absolute;
                bottom: -3px;
                left: 0;
            }
        }

        .link {
            cursor: pointer;
        }
    }

    figure {
        margin: 0;
        position: absolute;
        bottom: -18px;
        left: 50%;
        transform: translateY(100%);
        pointer-events: none;

        svg {
            --arrow-width: 14px;
            fill: none;
            stroke-linecap: round;
            stroke: rgb(var(--primary-color));
            stroke-width: var(--stroke-width);
            position: absolute;

            @media @laptop-screen {
                --arrow-width: 20px;
            }

            &.curve {
                overflow: visible;
                top: 0;
                left: 0;
                height: 100%;
                width: calc(100% - var(--arrow-width));

                path.dashed {
                    stroke-dasharray: 4, 5;

                    @media @laptop-screen {
                        stroke-dasharray: 10, 12;
                    }
                }
            }

            &.arrow {
                width: var(--arrow-width);
                bottom: 0;
                right: 0;
                transform: translateY(45%);
            }
        }
    }
}
</style>
