<template>
    <svg :viewBox="`0 0 ${width} ${height}`" fill="currentColor" class="illustration">
        <component :is="illustration" @dimensions="dimensions"/>
    </svg>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'Illustration',
    emits: ['loaded'],
    props: {
        name: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            width: 0,
            height: 0,
        };
    },
    setup(props) {
        const illustration = defineAsyncComponent(() => import(`@/assets/illustrations/${props.name}.vue`));
        return { illustration };
    },
    methods: {
        dimensions(data) {
            this.width = data.width;
            this.height = data.height;
            this.$emit('loaded');
        },
    },
};
</script>
