<template>
    <Main :show-title="false">
        <Empty illustration="contracts">
            <template #title>Log ind på din<br>Selvbygger+ konto</template>
            <template #description>og få adgang til funktionerne på Selvbygger+</template>
        </Empty>
        Har du ikke en bruger?&nbsp;<router-link to="/register">Opret bruger</router-link>
    </Main>
    <aside>
        <h1>{{ headline }}</h1>
        <Form @submit="forgotPasswordView ? requestPassword() : login()" :processing="processing"
              :message="message">
            <fieldset>
                <Input type="email" placeholder="E-mail*" v-model="email"
                       :required="true" autocomplete="username email" ref="email"/>

                <Input type="password" placeholder="Adgangskode*" v-model="password"
                       :required="!forgotPasswordView" autocomplete="current-password"
                       ref="password" v-if="!forgotPasswordView"/>

                <ActionButton :label="headline"/>
            </fieldset>
        </Form>
        <div class="second-actions">
            <a href="javascript:void(0)" @click.prevent="toggleView">{{ secondActionLabel }}</a>
        </div>
    </aside>
</template>

<script>
import Main from '@/components/Main.vue';
import { auth } from '@/utils/firebase';
import ActionButton from '@/components/buttons/ActionButton.vue';
import useState from '@/store';
import Form from '@/components/Form.vue';
import Input from '@/components/inputs/Input.vue';
import Empty from '@/components/Empty.vue';
import { useRoute } from 'vue-router';
import { computed, ref } from 'vue';

export default {
    name: 'Login',
    components: {
        Main,
        ActionButton,
        Form,
        Input,
        Empty,
    },
    data() {
        return {
            email: null,
            password: null,
            processing: false,
            message: null,
        };
    },
    setup() {
        const { initState } = useState();
        const { title } = useRoute().meta;
        const forgotPasswordView = ref(false);
        const headline = computed(() => (forgotPasswordView.value ? 'Gendan adgangskode' : title));
        const secondActionLabel = computed(() => (forgotPasswordView.value ? 'Vil du prøve at logge ind?' : 'Glemt adgangskode?'));

        return { initState, headline, forgotPasswordView, secondActionLabel };
    },
    methods: {
        toggleView() {
            this.message = null;
            this.forgotPasswordView = !this.forgotPasswordView;
        },
        async login() {
            this.processing = true;

            try {
                const loginResponse = await auth.signInWithEmailAndPassword(this.email, this.password);
                await this.initState(loginResponse.user.uid);
                await this.$router.push('/');
            } catch (err) {
                console.log(err);
                this.processing = false;
                this.handleErrorMessages(err);
            }
        },
        async requestPassword() {
            this.processing = true;
            try {
                await auth.sendPasswordResetEmail(this.email);
                this.message = {
                    text: `En mail er sendt til <strong>${ this.email }</strong> med link til gendannelse af adgangskode.`,
                    error: false,
                };
                this.processing = false;
            } catch (err) {
                this.processing = false;
                this.handleErrorMessages(err);
            }
        },
        handleErrorMessages(error) {
            if (error.code === 'auth/user-not-found') {
                this.$refs.email.$el.setAttribute('data-invalid', 'Ingen brugere med denne e-mail blev fundet');
                this.$refs.email.$el.classList.add('show-invalid');
            } else if (error.code === 'auth/wrong-password') {
                this.$refs.password.$el.setAttribute('data-invalid', 'Adgangskoden er desværre forkert');
                this.$refs.password.$el.classList.add('show-invalid');
            } else if (error.code === 'auth/too-many-requests') {
                this.message = {
                    text: 'Adgangen til denne konto er midlertidigt deaktiveret på grund af for mange mislykkede loginforsøg. Du kan nulstille din adgangskode, eller prøve igen senere.',
                    error: true,
                };
            } else {
                this.message = { text: error.message, error: true };
            }
        },
    },
};
</script>

<style scoped lang="less">

div.second-actions {
    max-width: var(--fieldset-max-width);
    text-align: center;
    margin-top: 30px;
    font-weight: var(--semibold-weight);
    color: rgb(var(--grey-color));

    a:hover {
        color: rgb(var(--primary-color));
    }
}
</style>
